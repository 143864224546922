import { request, noTimeOutReq } from '@/utils/request.js'

//根据考试id获取阅卷规则信息
export function getMarkRuleListApi (examId) {
  return request({
    url: `/exam/score/admin/mark/rule/get`,
    method: 'post',
    params: { examId: examId },
  })
}

//获取阅卷规则信息
export function getMarkRuleApi (markRuleId) {
  return request({
    url: `/exam/score/admin/mark/rule/${markRuleId}/get`,
    method: 'post',
    params: { examId: examId },
  })
}

//修改阅卷规则
export function updateMarkRuleApi (data) {
  return request({
    url: `/exam/score/admin/mark/rule/update`,
    method: 'post',
    data: data,
  })
}

// 考后评更新阅卷规则
export function updateExamedMarkRuleApi(data) {
  return request({
    url: `/exam/score/admin/mark/post/rule/update`,
    method: 'post',
    data,
  })
}

//获取已选教师列表
export function getChosenTeacherListApi (data) {
  return request({
    url: `/exam/score/admin/mark/teacher/chosen/list`,
    method: 'post',
    data: data,
  })
}

//获取待选教师列表
export function getUnChosenTeacherListApi (data) {
  return request({
    url: `/exam/score/admin/mark/teacher/unChosen/list`,
    method: 'post',
    data: data,
  })
}

//添加阅卷教师
export function addMarkTeacherApi (markRuleId, teacherIds) {
  return request({
    url: `/exam/score/admin/mark/teacher/${markRuleId}/add`,
    method: 'post',
    params: { teacherIds: teacherIds.join(',') },
  })
}

//移除阅卷教师
export function removeMarkTeacherApi (markRuleId, teacherIds) {
  return request({
    url: `/exam/score/admin/mark/teacher/${markRuleId}/delete`,
    method: 'post',
    params: { examMarkTeacherIds: teacherIds.join(',') },
  })
}

//移除阅卷教师
export function updateMarkTeacherTypeApi (
  markRuleId,
  teacherType,
  examMarkTeacherIds
) {
  return request({
    url: `/exam/score/admin/mark/${markRuleId}/${teacherType}/update`,
    method: 'post',
    params: { examMarkTeacherIds: examMarkTeacherIds.join(',') },
  })
}


//获取阅卷教师
export function getUserRoleListApi (data) {
  return noTimeOutReq({
    url: `/user/role/query/list`,
    method: 'post',
    data: data
  })
}